<template>
  <div class="listPage">
    <div class="line" v-if="list.length > 0"></div>
    <div class="listBox" v-if="list.length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <List
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-for="item in list">
            <div :key="item.id" class="meet">
              <div class="meetName" @click="goDetailPage(item)">
                <div class="name">
                  <img src="../../assets/myIcon2.png" width="20px" />
                  <span>{{item.sellerName}}</span>
                </div>
                <Icon class="arrow" name="arrow" size="16" color="#666" v-if="item.invoiceStatusUn == 1 "/>
              </div>
              <div class="meetName meetTime" @click="goDetailPage(item)">
                <div class="timeBox">
                  <div>
                    <span class="nameBox">金额：</span>
                    <span class="time">{{item.invoiceAmount}}元</span>
                  </div>
                  <div>
                    <span class="nameBox">时间：</span>
                    <span class="time">{{item.invoicePrintDate||'-'}}</span>
                  </div>
                  <div>
                    <span class="nameBox">付款方：</span>
                    <span class="time">{{item.custName}}</span>
                  </div>
                </div>
                <span :class="item.invoiceStatusUn == 1 ? 'blueColor' : ''">{{
                  ['',"已开发票", "开票失败",'开票中'][item.invoiceStatusUn]
                }}</span>
              </div>
            </div>
          </template>
        </List>
      </van-pull-refresh>
    </div>
    <div v-else class="noMeet">
      <Icon name="notes-o" color="#999" size="100" />
      <span>暂时没有任何发票</span>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Icon, List } from "vant";
import {getInvoiceList} from "@/api/my.js"

export default {
  name: "myInvoice",
  components: {
    Tab,
    Tabs,
    Icon,
    List,
  },
  data() {
    return {
      activeIdx: 0,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.getList(1);
  },
  methods: {
    getList(page) {
      let userId = localStorage.getItem("clientId");
      if (this.list.length < this.total || page == 1) {
        getInvoiceList({
          userId,
          pageIndex: page,
          pageSize: this.pageSize,
        }).then((res) => {
          this.loading = false;
          this.refreshing = false;
          if (res.status == "S") {
            let { list, total } = res.data;
            let newList = page == 1 ? list : [...this.list, ...list];
            this.list = [...newList];
            this.pageIndex = page;
            this.total = total;
          }
        });
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
    },
    goDetailPage(item) {
      //已开发票的才能进去
      if(item.invoiceStatusUn == 1 ){
        this.$router.push(`/previewInvoice?id=${item.id}&imgUrl=${item.pdfPath1}`);
      }
    },
    onLoad() {
      this.getList(this.pageIndex + 1);
    },
    onRefresh() {
      this.loading = true;
      this.total=0;
      this.finished = false;
      this.getList(1);
    },
  },
};
</script>

<style lang="less" scoped>
@import "./../meetList/index.less";
.listPage {
  padding-top: 0rem;
  .line {
    height: 0.12rem;
    background: #f5f5f5;
  }
  .listBox {
    max-height: calc(~"100vh - 0.12rem");
    overflow-y: auto;
    .meetTime {
      .timeBox {
        height: 0.7rem;
        width: 70%;
        &>div{
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .nameBox{
            display: inline-block;
            width: 58px;
        }
        .time{
          display: inline-block;
          width: calc(~"100vw - 1.8rem");
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .blueColor{
        color:#0FA84F
      }
    }
  }
  .noMeet {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-top: 0.3rem;
      color: #999;
      font-size: 22px;
      font-weight: bold;
    }
  }
}
</style>
